<template>
  <div>
    <v-card :elevation="0" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Usuarios Externos
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <!-- <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-right  darken-2"
              @click="modalNuevo"
            >
              Registrar nuevo
            </v-btn> -->

            <v-btn
              color="light-blue-502"
              class="mb-2 float-right bg-light-blue"
              dark
              @click="modalNuevo(1)"
            >
              Registrar usuario
            </v-btn>

            <!-- <v-menu
                bottom
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="light-blue-502"
                    class="mb-2 float-right bg-light-blue"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    Registrar nuevo
                </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="modalNuevo(1)">
                        <v-list-item-title>Administradores</v-list-item-title>
                    </v-list-item>

                     <v-list-item @click="modalNuevo(2)">
                        <v-list-item-title>Administrador proyectos</v-list-item-title>
                    </v-list-item> 

                      <v-list-item @click="modalNuevo(3)">
                        <v-list-item-title>Institucional</v-list-item-title>
                    </v-list-item> 
                </v-list>
            </v-menu> -->
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-text-field
              autocomplete="off"
              class=""
              dense
              filled
              color="blue-grey lighten-2"
              v-model="search"
              label="Buscar"
              maxlength="100"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="usuarios"
          :search="search"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          noResultsText="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por',
          }"
          :custom-filter="customSearch"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.usuario }}</td>
              <td>{{ item.nombre != null ? item.nombre : "" }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.institucion }}</td>
              <td>
                <li
                  style="list-style: none;"
                  v-for="item in item.rolesAsignados"
                  :key="item.rol"
                >
                  {{ item.rol.replace("SCO$", "") }}
                </li>
              </td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadoId === 1
                      ? 'cyan lighten-4'
                      : item.estadoId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadoId === 6
                      ? 'cyan lighten-4'
                      : item.estadoId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadoId === 1
                      ? 'cyan darken-1'
                      : item.estadoId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadoId === 6
                      ? 'cyan darken-1'
                      : item.estadoId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.rolesAsignados.length > 0
                      ? 'cyan lighten-4'
                      : 'deep-orange lighten-4'
                  "
                  :text-color="
                    item.rolesAsignados.length > 0
                      ? 'cyan darken-1'
                      : 'deep-orange lighten-1'
                  "
                  small
                >
                  {{
                    item.rolesAsignados.length > 0
                      ? `Permitido`
                      : `No permitido`
                  }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerUsuario(item.usuario)"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>
                <!-- <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerUsuario(item.usuario, 2)"
                >
                  <v-icon left>mdi-account-details</v-icon> Asignar roles Restrictiva
                </v-btn> -->

                <v-btn
                  v-if="item.rolesAsignados"
                  class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarDialogEstado(
                      item.usuario,
                      item.rolesAsignados.length
                    )
                  "
                >
                  <v-icon left>{{
                    item.rolesAsignados.length > 0
                      ? `mdi-account-remove`
                      : `mdi-account-check`
                  }}</v-icon>
                  {{
                    item.rolesAsignados.length > 0
                      ? `Denegar acceso`
                      : `Permitir acceso`
                  }}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="1024px"
      persistent
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title>
          {{ modalTitle }}
          {{
            nombreUsuario != null && nombreUsuario.lengt > 0
              ? ` - ` + nombreUsuario
              : ``
          }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialog = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-0"></v-divider>

        <v-card-text class="pl-6 pr-6">
          <!-- <v-tabs
              v-model="tab"
              background-color="light-blue-502"
              centered
              dark
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1">
                  Datos generales
                <v-icon>mdi-arrow-collapse-up</v-icon>
              </v-tab>

              <v-tab href="#tab-2" :disabled="!registrado">
                  Roles
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item :value="'tab-1'">
                <v-card flat>
                  <v-card-text> -->

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mt-4">
              <v-subheader class="text-h6 black--text">
                Roles del usuario
              </v-subheader>
            </v-col>
          </v-row>

          <v-form
            ref="form"
            v-on:submit.prevent="registrarUsuario"
            v-model="validForm"
          >
            <!--  <v-row class="mt-4">

                        <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                          <v-select
                              v-model="rolSeleccionado"
                              :items="rolesFiltrados"
                              class="required"
                              dense
                              outlined
                              :no-data-text="rolesFiltrados != null ? 'Seleccione los roles' : 'No se han encontrado roles'"
                              label="Seleccionar rol"
                              item-text="nombreRol"
                              item-value="id"
                              chips
                              :rules="selectRequired('rol')"
                          >
                          </v-select>
                        </v-col>
                      </v-row> -->
            <v-row class="mt-2">
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                <v-select
                  v-model="rolesSeleccionados"
                  :items="rolesModificados"
                  class="required"
                  dense
                  outlined
                  :no-data-text="
                    roles != null
                      ? 'Seleccione los roles'
                      : 'No se han encontrado roles'
                  "
                  label="Seleccionar Rol del usuario"
                  item-text="descripcion"
                  item-value="id"
                  return-object
                  multiple
                  chips
                  :rules="multipleSelectRules.required"
                >
                  <!-- @change="validateSelectedRoles"<template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @click="toggle"
                                    >
                                    <v-list-item-action>
                                        <v-icon :color="rolesSeleccionados.length > 0 ? 'indigo darken-4' : ''">
                                        {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        Seleccionar todos
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template> -->
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mt-4">
                <v-subheader class="text-h6 black--text">
                  Datos generales del usuario
                </v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.nombres"
                  label="Nombres"
                  :rules="[required('nombres del usuario')]"
                  maxlength="100"
                ></v-text-field>
              </v-col>

              <v-col md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.apellidos"
                  label="Apellidos"
                  :rules="[required('apellidos del usuario')]"
                  maxlength="100"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.email"
                  label="Correo electrónico"
                  :rules="[required('Correo usuario'), validEmail('')]"
                  :disabled="accion === 2"
                  maxlength="100"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                <v-text-field
                  v-model="datosUsuario.area"
                  class="required"
                  dense
                  filled
                  :loading="ddRolesLoading"
                  label="Área o dirección a la que pertenece"
                  item-text="area"
                  item-value="id"
                  :rules="[
                    required('área o dirección a la que pertenece'),
                    minLength('área o dirección a la que pertenece', 3),
                    maxLength('área o dirección a la que pertenece', 150),
                  ]"
                  maxlength="150"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                <v-text-field
                  v-model="datosUsuario.cargo"
                  class="required"
                  dense
                  filled
                  :loading="ddRolesLoading"
                  label="Cargo (Puesto nominal)"
                  item-text="cargo"
                  item-value="id"
                  :rules="[
                    required('cargo (Puesto nominal)'),
                    minLength('área o dirección a la que pertenece', 3),
                    maxLength('área o dirección a la que pertenece', 150),
                  ]"
                  maxlength="150"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                <v-autocomplete
                  v-model="institucionesId"
                  :items="instituciones"
                  class="required"
                  dense
                  filled
                  :loading="ddInstitucionesLoading"
                  label="Institución"
                  :item-text="
                    (item) =>
                      item.sigla
                        ? `${item.nombreInstitucion} (${item.sigla})`
                        : item.nombreInstitucion
                  "
                  item-value="id"
                  :rules="[selectRequired('institución')]"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!--
                      <v-row v-if="cooperanteBi || cooperanteMulti">
                        <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                            <v-subheader class="text-h6 black--text">
                              Datos fuente cooperante
                            </v-subheader>
                        </v-col>
                        <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0"  v-if="cooperanteBi">
                          <v-select
                            v-model="fuenteBilateralSeleccionada"
                            :items="fuentesBilaterales"
                            :loading="ddFuentesBilateralesLoading"
                            :required="cooperanteBi"
                            dense
                            :class="cooperanteBi ? 'required' : ''"
                            filled
                            :disabled="!cooperanteBi"
                            label="País Fuente Bilateral"
                            item-text="pais"
                            item-value="id"
                            return-object
                            :rules="[
                                cooperanteBi ? selectRequired('país bilateral') : true,
                            ]"
                            :no-data-text="
                                fuentesBilaterales != null
                                ? 'Selecciona un país'
                                : 'No se han encontrado países'
                            "
                            @change="obtenerAgenciasPorFuenteBilateral(); agenciaSeleccionada={};"
                            menu-props="auto"
                          ></v-select>
                      </v-col>

                     inicio:: agencias 
                      <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="cooperanteBi">
                          <v-select
                            v-model="agenciaSeleccionada"
                            :items="agencias"
                            :loading="ddAgenciasLoading"
                            :clearable="true"
                            dense
                            filled
                            :disabled="!cooperanteBi"
                            label="Seleccione Agencia"
                            item-text="nombreAgencia"
                            item-value="id"
                            return-object
                            :no-data-text="
                                agencias > 0
                                ? 'Selecciona una agencia'
                                : 'No se han encontrado agencias'
                            "
                           
                            menu-props="auto"
                          ></v-select>
                      </v-col>
                       fin:: agencias 


                       Cooperante multilateral 
                      <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="cooperanteMulti">
                          <v-select
                              v-model="fuenteMultilateralSeleccionada"
                              :items="fuentesMultilaterales"
                              :loading="ddFuentesMultilateralesLoading"
                              :class="cooperanteMulti ? 'required' : ''"
                              dense
                              filled
                              :disabled="!cooperanteMulti"
                              label="Seleccione fuente multilateral"
                              item-text="nombreFuenteMultilateral"
                              item-value="id"
                              return-object
                              :no-data-text="
                                  fuentesMultilaterales > 0
                                  ? 'Seleccione una fuente multilateral'
                                  : 'No se han encontrado fuentes multilaterales'
                              "
                              :rules="[
                                  cooperanteMulti ? selectRequired('fuente multilateral') : true,
                              ]"
                              @change="obtenerAgenciasMultilaterales(fuenteMultilateralSeleccionada.id); agenciaMultilateralSeleccionada={};"
                              menu-props="auto"
                          ></v-select>
                      </v-col>

                     inicio:: agencias 
                      <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="cooperanteMulti">
                          <v-select
                              v-model="agenciaMultilateralSeleccionada"
                              :items="agenciasMultilaterales"
                              :clearable="true"
                              :disabled="!cooperanteMulti"
                              :loading="ddAgenciasMultiLoading"
                              dense
                              filled
                              label="Seleccione Agencia Multilateral"
                              item-text="agencia"
                              item-value="id"
                              return-object
                              :no-data-text="
                              agenciasMultilaterales > 0
                                  ? 'Selecciona una agencia'
                                  : 'No se han encontrado agencias para la fuente seleccionada'
                              "
                              menu-props="auto"
                          ></v-select>
                      </v-col>
                      fin:: agencias -->

            <!--fin:: cooperante multilateral

                      </v-row>-->

            <v-row class="mt-2" v-if="cooperanteMulti || cooperanteBi">
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                <v-select
                  v-model="datosUsuario.cooperanteId"
                  :items="cooperantes"
                  :loading="ddCooperantesLoading"
                  :class="cooperanteMulti || cooperanteBi ? `required` : ``"
                  dense
                  filled
                  :no-data-text="
                    cooperantes != null
                      ? 'Seleccione cooperante'
                      : 'No se han encontrado cooperantes'
                  "
                  label="Seleccione el cooperante"
                  item-text="fuente"
                  item-value="id"
                  :rules="[
                    cooperanteMulti || cooperanteBi
                      ? selectRequired('cooperante')
                      : true,
                  ]"
                >
                  <template v-slot:selection="{ item, index }">
                    <div class="v-select__selection v-select__selection--comma">
                      {{ item.agencia ? item.agencia + `, ` : ``
                      }}{{ item.fuente }}
                    </div>
                  </template>
                  <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span
                              >{{ item.agencia ? item.agencia + `, ` : ``
                              }}{{ item.fuente }}</span
                            >
                            <v-spacer></v-spacer>
                          </v-row>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.nombreTipoFuente }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <!-- <v-row>
                        <v-col md="12" sm="12" class="pt-1 pb-1">
                          <label class="grey--text body-2">Estado</label>
                          <v-switch
                            v-model="switchItemEstado"
                            inset
                            :label="switchItemEstado ? 'Activo' : 'Inactivo'"
                          >
                          </v-switch>
                        </v-col>
                      </v-row> -->

            <v-row>
              <v-col md="12" sm="12" class="pt-1 pb-1">
                <v-spacer></v-spacer>

                <!--:disabled="!validDocForm" type="submit"-->
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validForm"
                  :loading="btnRegistroLoading"
                >
                  {{ btnRegistroText }}
                </v-btn>

                <v-btn
                  color="grey lighten-5"
                  elevation="0"
                  class="mb-2 float-right grey lighten-5 mr-1"
                  @click="
                    dialog = false;
                    resetForm();
                  "
                  :disabled="btnRegistroLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <!-- </v-card-text>
                </v-card> -->
          <!--  </v-tab-item>

              <v-tab-item :value="'tab-2'">
                <v-card flat>
                  <v-card-text>
                    
                    <v-row>
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                          <v-subheader class="text-h6 black--text">
                            Asignación de roles
                        </v-subheader>
                      </v-col>
                    </v-row>

                    <v-form
                      ref="form"
                      v-on:submit.prevent="asignarRol(rolSeleccionado, 1)"
                      v-model="validFormRol"
                    >

                      <v-row class="mt-4">
                          <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                              <v-select
                                  v-model="rolSeleccionado"
                                  :items="rolesFiltrados"
                                  class="required"
                                  dense
                                  outlined
                                  :no-data-text="rolesFiltrados != null ? 'Seleccione los roles' : 'No se han encontrado roles'"
                                  label="Seleccionar rol"
                                  item-text="nombreRol"
                                  item-value="id"
                                  chips
                                  :rules="multipleSelectRules.required"
                                  
                              >
                              
                              </v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col md="12" sm="12" class="pt-1 pb-1">
                            <v-spacer></v-spacer>
                            
                            <v-btn
                              color="light-blue-502"
                              class="white--text mb-2 float-right"
                              type="submit"
                              :elevation="0"
                              :disabled="!validFormRol"
                              :loading="btnRolLoading"
                            >
                              Asignar rol
                            </v-btn>
                          </v-col>

                        </v-row>
                      
                    </v-form>

                    <v-row>
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                          <v-subheader class="text-subtitle-1 black--text">
                            Roles asignados
                          </v-subheader>
                      </v-col>
                    </v-row>

                    <v-row>
                     
                      <v-col cols="12" md="12" sm="12" class="pt-4">
                          <v-data-table
                              class="elevation-1"
                              :headers="headersRoles"
                              :items="rolesAsignados"
                              :loading="tableRolesLoading"
                              hide-default-footer
                              no-data-text="No existen roles asignados al usuario"
                              :footer-props="{
                                  showFirstLastPage: true,
                                  firstIcon: 'mdi-page-first',
                                  lastIcon: 'mdi-page-last',
                                  prevIcon: 'mdi-chevron-left',
                                  nextIcon: 'mdi-chevron-right',
                                  'items-per-page-text': 'Registros por página',
                                  pageText: '{0}-{1} de {2}',
                                  
                              }"
                              
                              >

                                  <template v-slot:item="{ item }">
                                      <tr>
                                         
                                          <td>{{ item.replace("SCO$", "") }}</td>
                                          
                                          <td>
                                              <v-btn
                                                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                  small
                                                  depressed
                                                  :disabled="btnEliminarRolDisabled"
                                                  :loading="btnEliminarRolDisabled"
                                                  color="blue-grey lighten-5"
                                                  @click="asignarRol(item, 2)"
                                                  >
                                                  <v-icon left>mdi-delete</v-icon> Eliminar rol
                                              </v-btn>
                                          </td>
                                      </tr>
                           </template>
                        </v-data-table>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog cambio de estado del usuario -->
    <v-dialog
      v-model="dialogSuspension"
      width="600"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ tipoAccion }} usuario
        </v-card-title>

        <v-card-text>
          <v-row class="mt-4">
            <v-col sm="12">
              <p class="text-h6">
                Está seguro de {{ tipoAccion }} el usuario
                <span class="black--text">{{ datosEstado.usuario }}</span>
              </p>
            </v-col>

            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-0 pb-0"
              v-if="this.datosEstado.nuevaRestrictiva === 'S'"
            >
              <v-alert outlined type="warning" dense>
                Esta acción deshabilitará el acceso del usuario al Sistema de
                Cooperación SICOOPERA.GT
              </v-alert>
            </v-col>

            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-0 pb-0"
              v-if="this.datosEstado.nuevaRestrictiva === 'N'"
            >
              <v-alert outlined type="info" dense>
                Esta acción habilitará nuevamente el acceso del usuario al
                Sistema de Cooperación SICOOPERA.GT
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="
              dialogSuspension = false;
              resetForm();
            "
            :disabled="btnEstadoLoading"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="light-blue-502"
            class="white--text mb-2 float-right"
            type="submit"
            dark
            :loading="btnEstadoLoading"
            @click="actualizarEstadoItem()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import validations from "@/core/untils/validations.js";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";

import {
  OBTENER_ITEMS_CATALOGO,
  OBTENER_SUBITEMS_CATALOGO,
} from "@/core/services/store/catalogos/catalogointerno.module";

import {
  OBTENER_USUARIOS_ADSIS,
  REGISTRAR_USUARIO_ADSIS,
  OBTENER_USUARIO_ADSIS,
  ACTUALIZAR_USUARIO_ADSIS,
  ASIGNAR_ROL_ADSIS,
  ACTUALIZAR_ESTADO_USUARIO_ADSIS,
} from "@/core/services/store/usuarios/usuario.module";
import { OBTENER_ROLES } from "@/core/services/store/roles/rol.module";
import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";
import { OBTENER_COOPERANTES_CNSC } from "@/core/services/store/cooperantes/cooperante.module";

export default {
  name: "AdminUsuarios",
  data() {
    return {
      dialogLoaderVisible: false,
      tableLoading: false,
      dialogLoaderText: "",
      validForm: false,
      ddRolesLoading: false,
      switchItemEstado: true,
      dialog: false,
      accion: 1,
      validFormRol: false,
      btnRolLoading: false,
      modalTitle: "Registro de usuario",
      //accion: 1,
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      idUsuario: 0,
      search: "",
      instituciones: [],
      ddInstitucionesLoading: false,
      tipoRol: 0,
      rolSeleccionado: [],
      rolesSeleccionados: [],
      tab: null,
      nombreUsuario: null,
      registrado: false,
      btnEliminarRolDisabled: false,
      datosRol: {},
      tableRolesLoading: false,
      cooperanteBi: false,
      cooperanteMulti: false,
      fuentesBilaterales: [],
      fuenteBilateralSeleccionada: {},
      fuentesMultilaterales: [],
      agenciasMultilaterales: [],
      fuenteMultilateralSeleccionada: {},
      agenciaSeleccionada: {},
      ddFuentesBilateralesLoading: false,
      ddAgenciasLoading: false,
      ddAgenciasMultiLoading: false,
      ddFuentesMultilateralesLoading: false,
      agenciaMultilateralSeleccionada: {},
      rolesModificados: [],
      cooperantes: [],
      ddCooperantesLoading: false,
      roles: [],
      rolesFiltrados: [],
      rolesAsignados: [],
      tipo3: [
        "SCO$UNIDADEJECUTORA",
        "SCO$NORTESUR",
        "SCO$COOPERANTEBI",
        "SCO$COOPERANTEMULTI",
        "SCO$ALIANZAS",
        "SCO$SURSUR",
        "SCO$CCCI",
      ],

      cargos: [
        { id: "ESPECIALISTA", cargo: "Especialista" },
        { id: "ANALISTA", cargo: "Analista" },
      ],

      areas: [
        { id: "DASCD", area: "DASCD" },
        { id: "DAD", area: "DAD" },
      ],
      institucionesId: 0,
      datosUsuario: {
        nombres: "",
        apellidos: "",
        email: "",
        institucion: "",
        area: "",
        roles: [],
        cooperanteId: 0,
        cooperanteAnteriorId: 0,
        estado: 0,
        tipoUsuario: 2 //externo
      },
      multipleSelectRules: {
        required: [
          (v) =>
            v.length > 0 || "Debe seleccionar al menos un rol para el usuario",
        ],
      },
      ...validations,
      usuarios: [],
      usuario: {},
      tipoAccion: "",
      datosEstado: {
        usuario: "",
        nuevaRestrictiva: "",
      },
      dialogSuspension: false,
      btnEstadoLoading: false,
      rolDesactivar: {},
    };
  },
  components: {
    SnackAlert,
    DialogLoader,
  },
  methods: {
    customSearch(value, search, item) {
      if (Array.isArray(value)) {
        return value.some(item=>Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search)))
      }
      return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllRoles) {
          this.rolSeleccionado = [];
        } else {
          this.rolSeleccionado = this.roles.slice();
        }
      });
    },
    /**
     * Tipos
     *  1 Administrador
     *  2 Administrador de proyectos (cns, css, alianzas, cci, etc)
     *  3 Institucionales (unidad ejecutora, etc)
     * */
    modalNuevo(tipo) {
      this.nombreUsuario = "";
      this.tab = "tab-1";
      this.tipoRol = tipo;
      //this.filtrarRoles(tipo);
      this.rolesFiltrados = this.rolesModificados;
      this.registrado = false;

      this.dialog = true;
      this.accion = 1;
      this.modalTitle = "Registrar Usuario";
      this.rolesSeleccionados = [];
      this.btnRegistroText = "Guardar";
    },

    filtrarRoles(tipo) {
      this.rolesFiltrados = [];
      this.rolesFiltrados = this.roles.filter(function(el) {
        return el.tipo == tipo;
      });
    },

    //Validar los roles seleccionados
    validateSelectedRoles() {
      this.cooperanteBi = false;
      this.cooperanteMulti = false;

      //console.log(this.rolesSeleccionados)
      //Buscar el rol administrador para bloquear la asignacion de otro rol

      const rolAdmin = this.rolesSeleccionados.find(
        rol => rol.id === "SCO$ADMINISTRADOR"
      );
      const rolSubAdmin = this.rolesSeleccionados.find(
        rol => rol.id === "SCO$SUBADMINISTRADOR"
      );

      const cooperanteBi = this.rolesSeleccionados.find(
        rol => rol.id === "SCO$COOPERANTEBI"
      );

      const cooperanteMulti = this.rolesSeleccionados.find(
        rol => rol.id === "SCO$COOPERANTEMULTI"
      );

      const institucionCSSC = this.rolesSeleccionados.find(
        rol => rol.id === "SCO$INSTCSSC"
      );

      const rolSeguimiento = this.rolesSeleccionados.filter((rol) => {
        return (
          rol.id === "SCO$SEGUIMIENTOACTLOCALES" ||
          rol.id === "SCO$SEGUIMIENTOALIANZAS" ||
          rol.id === "SCO$SEGUIMIENTOCNS" ||
          rol.id === "SCO$SEGUIMIENTOCNSC" ||
          rol.id === "SCO$SEGUIMIENTOCSSC" ||
          rol.id === "SCO$SEGUIMIENTOCSSD"
        );
      });

      if (rolAdmin) {
        this.rolesFiltrados.forEach(function(rol) {
          if (rol.id != "SCO$ADMINISTRADOR") {
            rol.disabled = true;
          } else {
            rol.disabled = false;
          }
          // console.log(rol)
        });
      } else if (rolSubAdmin) {
        this.rolesFiltrados.forEach(function(rol) {
          if (rol.id != "SCO$SUBADMINISTRADOR") {
            rol.disabled = true;
          }
        });
      } else if (cooperanteBi) {
        this.cooperanteBi = true;
        //this.obtenerFuentesBilaterales();
        this.obtenerCooperantes(1);
        this.rolesFiltrados.forEach(function(rol) {
          if (rol.id != "SCO$COOPERANTEBI") {
            rol.disabled = true;
          }
        });
      } else if (cooperanteMulti) {
        //this.obtenerFuentesMultilateral();
        this.obtenerCooperantes(2);
        this.cooperanteMulti = true;
        this.rolesFiltrados.forEach(function(rol) {
          if (rol.id != "SCO$COOPERANTEMULTI") {
            rol.disabled = true;
          }
        });
      } else if (rolSeguimiento) {
        this.rolesFiltrados.forEach(function(rol) {
          if (
            rol.id != "SCO$SEGUIMIENTOACTLOCALES" &&
            rol.id != "SCO$SEGUIMIENTOALIANZAS" &&
            rol.id != "SCO$SEGUIMIENTOCNS" &&
            rol.id != "SCO$SEGUIMIENTOCNSC" &&
            rol.id != "SCO$SEGUIMIENTOCSSC" &&
            rol.id != "SCO$SEGUIMIENTOCSSD"
          ) {
            rol.disabled = true;
          }
          //console.log(rol)
        });
      } else if (institucionCSSC) {
        this.rolesFiltrados.forEach(function(rol) {
          if (rol.id != "SCO$INSTCSSC") {
            rol.disabled = true;
          }
        });
      }

      if (this.rolesSeleccionados.length <= 0) {
        this.rolesFiltrados.forEach(function(rol) {
          rol.disabled = false;
        });
      }

      //Validar si es cooperante bi o multi para habilitar fuente cooperante y agencia

      // console.log(this.rolesSeleccionados)

      //const rolSubAdmin = this.rolesSeleccionados.find( rol => rol.tipo === 2 );

      /* if(rolAdmin && this.rolesSeleccionados.length > 1){
       this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Administrador es un rol único. No se permite combinar con otros roles`);
       this.rolesSeleccionados = this.rolesSeleccionados.filter(rol => rol.tipo != 1);
      } else if(rolAdmin && this.rolesSeleccionados.length == 1) {
        console.log("encontrado una vez")
      } else {
        console.log("no encontrado")
      }
      console.log(this.rolesSeleccionados)
      */
      /*  this.rolesSeleccionados.forEach(function(rol){
          console.log(rol)
      }); */
    },

    //Obtener los roles
    async obtenerRoles() {
      //this.tableLoading=true;
      this.roles = [];
      this.rolesModificados = [];
      await this.$store
        .dispatch(OBTENER_ROLES, { estadoId: 1, tipoRol: "EXTERNO" })
        .then(res => {
          if (res.status === 200) {
            this.roles = res.data;

            this.roles.forEach(element => {
              if (element.rol != "SCO$INSTCNS" && element.rol != "SCO$INSTCSSC" && element.rol != "SCO$INSTCSSD" && element.rol != "SCO$INSTALIANZAS") {
                this.rolesModificados.push({
                  rol: element.rol.slice(4),
                  id: element.rol,
                  descripcion: element.descripcion,
                  disabled: false
                });
              }
            });
          }
          // this.tableLoading=false;
        })
        .catch(() => {
          this.roles = [];
          this.rolesModificados = [];
          // this.tableLoading=false;
        });
    },

    //Obtener Usuarios
    async obtenerUsuarios() {
      this.tableLoading = true;
      this.usuarios = [];
      await this.$store
        .dispatch(OBTENER_USUARIOS_ADSIS, { estadoId: 1, tipoUsuario: 2 })
        .then(res => {
          if (res.status === 200) {
            this.usuarios = res.data;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.usuarios = [];
          this.tableLoading = false;
        });
    },

    async obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      this.ddFuentesBilateralesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuentesBilaterales/all/1/0",
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
          }
          this.ddFuentesBilateralesLoading = false;
        })
        .catch(() => {
          this.fuentesBilaterales = [];
          this.ddFuentesBilateralesLoading = false;
        });
    },

    async obtenerAgenciasPorFuenteBilateral() {
      this.agencias = [];
      this.ddAgenciasLoading = true;
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {
          endpoint: "Agencias/all/1",
          id: this.fuenteBilateralSeleccionada.id,
        })
        .then(res => {
          if (res.status === 200) {
            this.ddAgenciasLoading = false;
            this.agencias = res.data;
          }
        })
        .catch(() => {
          this.ddAgenciasLoading = false;
          this.agencias = [];
        });
    },

    //Obtener fuentes multilaterales
    async obtenerFuentesMultilateral() {
      this.fuentesMultilaterales = [];
      this.ddFuentesMultilateralesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuenteMultiLateral/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilaterales = res.data;
          }
          this.ddFuentesMultilateralesLoading = false;
        })
        .catch(() => {
          this.fuentesMultilaterales = [];
          this.ddFuentesMultilateralesLoading = false;
        });
    },

    // Obtener agencias Multilaterales
    async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
      this.agenciasMultilaterales = [];
      this.ddAgenciasMultiLoading = true;

      await this.$store
        .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {
          estadoId: 1,
          fuentesMultilateralesId: fuenteMultilateralId,
        })
        .then(res => {
          if (res.status === 200) {
            this.agenciasMultilaterales = res.data;
          }
          this.ddAgenciasMultiLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.ddAgenciasMultiLoading = false;
        });
    },

    async obtenerCooperantes(tipoFuenteCooperante) {
      this.ddCooperantesLoading = true;
      this.cooperantes = [];
      await this.$store
        .dispatch(OBTENER_COOPERANTES_CNSC, {
          estadoId: 1,
          tipoFuenteCooperanteId: tipoFuenteCooperante,
        })
        .then(res => {
          if (res.status === 200) {
            this.cooperantes = res.data;
          }
          this.ddCooperantesLoading = false;
        })
        .catch(() => {
          this.cooperantes = [];
          this.ddCooperantesLoading = false;
        });
    },

    resetForm() {
      this.institucionesId = 0;
      this.datosUsuario = {
        nombres: "",
        apellidos: "",
        email: "",
        institucion: "",
        area: "",
        roles: [],
        estado: 0,
        cooperanteId: 0,
        cooperanteAnteriorId: 0,
        tipoUsuario: 2 //externo
      };
    },

    //Obtener entidades o instituciones
    async obtenerEntidades() {
      this.instituciones = [];
      this.ddInstitucionesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.instituciones = [];
          this.ddInstitucionesLoading = false;
        });
    },

    async registrarUsuario() {
      this.btnRegistroLoading = true;
      //this.datosProyeccionAnual.programacionAnualCNSId = this.proyectoId;
      //this.datosProyeccionCuatrimestre.estadosId = 1;
      //this.datosProyeccionCuatrimestre.usuarioCreacion = "admin";

      //Asignar los roles
      this.rolesSeleccionados.forEach(rol => {
        this.datosUsuario.roles.push({ rol: rol.id });
      });

      //console.log(this.datosUsuario.roles)

      //this.datosUsuario.roles = [{rol: this.rolSeleccionado}];
      this.datosUsuario.entidadId = parseInt(this.institucionesId);

      let dispatch = REGISTRAR_USUARIO_ADSIS;

      //Actualizar
      if (this.accion === 2) {
        dispatch = ACTUALIZAR_USUARIO_ADSIS;
      }

      await this.$store
        .dispatch(dispatch, { datos: this.datosUsuario })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            // this.nombreUsuario = this.datosUsuario.nombres;
            this.accion = 2;
            this.nombreUsuario =
              this.datosUsuario.nombres + ` ` + this.datosUsuario.apellidos;
            this.registrado = true;
            this.resetForm();
            this.obtenerUsuarios();
            this.dialog = false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            // this.obtenerProgramacionesCuatrimestre(this.datosProyeccionCuatrimestre.programacionAnualCNSId);
            //this.resetItemsProgCuatrimestre();

            this.btnRegistroLoading = false;
            //this.resetForm();
          } else {
            this.registrado = false;
            this.accion = 1;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.accion = 1;
        });
    },

    async obtenerUsuario(nick) {
      this.rolesFiltrados = this.rolesModificados;
      this.dialogLoaderText = "Obteniendo información del usuario...";
      this.dialogLoaderVisible = true;
      this.usuario = [];
      this.nombreUsuario = "";
      this.datosUsuario = {};
      this.institucionesId = 0;
      this.rolesSeleccionados = [];
      await this.$store
        .dispatch(OBTENER_USUARIO_ADSIS, { nick: nick })
        .then(res => {
          if (res.status === 200) {
            this.modalTitle = "Actualizar Usuario";
            this.registrado = true;
            this.usuario = res.data;
            this.accion = 2;
            this.nombreUsuario =
              this.usuario.nombres + ` ` + this.usuario.apellidos;

            this.datosUsuario = {
              nick: this.usuario.usuario,
              nombres: this.usuario.nombres,
              apellidos: this.usuario.apellidos,
              email: this.usuario.email,
              institucion: this.usuario.institucion,
              area: this.usuario.area,
              cargo: this.usuario.cargo,
              roles: [],
              estadoId: this.usuario.estadoId,
              cooperanteId: this.usuario.cooperanteId,
              cooperanteAnteriorId: this.usuario.cooperanteId,
            };

            // this.rolesSeleccionados = this.usuario.rolesAsignados;

            //Agregar los roles
            this.usuario.rolesAsignados.forEach((rolAsignado) => {
              let tipo = 3;
              let nombreRol = "";

              switch (rolAsignado) {
                case "SCO$ADMINISTRADOR":
                  tipo = 1;
                  nombreRol = "Administrador";
                  break;

                case "SCO$SUBADMINISTRADOR":
                  tipo = 1;
                  nombreRol = "Subadministrador";
                  break;

                case "SCO$SEGUIMIENTOCNS":
                  tipo = 3;
                  nombreRol = "Seguimiento Cooperación Norte-Sur";
                  break;

                case "SCO$SEGUIMIENTOCSS":
                  tipo = 3;
                  nombreRol = "Seguimiento Cooperación Sur-Sur";
                  break;

                case "SCO$SEGUIMIENTOALIANZAS":
                  tipo = 3;
                  nombreRol = "Seguimiento Alianzas";
                  break;

                case "SCO$SEGUIMIENTOCNSC":
                  tipo = 3;
                  nombreRol = "Seguimiento Cooperantes";
                  break;

                case "SCO$SEGUIMIENTOACTLOCALES":
                  tipo = 3;
                  nombreRol = "Seguimiento Actores Locales";
                  break;

                case "SCO$SEGUIMIENTOCOODESC":
                  tipo = 3;
                  nombreRol = "Seguimiento Cooperación Descentralizada";
                  break;

                case "SCO$INSTCSSC":
                  tipo = 2;
                  nombreRol = "Institución Cooperación Sur-Sur (Comixta)";
                  break;
              }

              this.rolesSeleccionados.push({
                id: rolAsignado,
                nombreRol: nombreRol,
                tipo: tipo,
                disabled: false,
              });
            });

            //console.log(this.rolesSeleccionados)
            this.validateSelectedRoles();

            //Validar el tipo de roles que tiene asignados para filtrar los que se pueden seleccionar
            /*let buscarAdmin = this.rolesAsignados.find(e => { return (e === "SCO$ADMINISTRADOR" || e === "SCO$SUBADMINISTRADOR" || e=="SCO$ESPECIALISTA")});
                if(buscarAdmin) this.filtrarRoles(1);

                let buscarAdminProyectos = this.rolesAsignados.find(e => e === "SCO$SEGUIMIENTOCNS" || e === "SCO$SEGUIMIENTOCSS");
                if(buscarAdminProyectos) this.filtrarRoles(2);

                let buscarInstitucionesExternas = this.rolesAsignados.find(e => this.tipo3.includes(e));
                if(buscarInstitucionesExternas) this.filtrarRoles(3);
                
                
                console.log("ADMIN: " + buscarAdmin + " ---- ADMIN PROY: " + buscarAdminProyectos + " --------- INSTITUCION EXTERNA: " + buscarInstitucionesExternas);*/

            if (this.usuario.institucionId)
              this.institucionesId = parseInt(this.usuario.institucionId);
            // this.institucionesId=this.usuario.institucionId;

            //seccion=== 1 ? this.tab = "tab-1" : this.tab = "tab-2";
            this.dialog = true;
          } else {
            this.registrado = false;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante la solicitud, por favor, póngase en contacto con un administrador.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la solicitud, por favor, póngase en contacto con un administrador.`
          );
          this.usuario = {};
          this.registrado = false;
          this.dialogLoaderVisible = false;
          this.accion = 1;
        });
    },

    // Asignar un rol al usuario
    async asignarRol(rol, accion) {
      this.btnEliminarRolDisabled = true;
      this.datosRol.rol = rol;
      this.datosRol.nick = this.datosUsuario.nick;
      this.datosRol.accion = accion;

      await this.$store
        .dispatch(ASIGNAR_ROL_ADSIS, { datos: this.datosRol })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            // this.nombreUsuario = this.datosUsuario.nombres;
            this.nombreUsuario =
              this.datosUsuario.nombres + ` ` + this.datosUsuario.apellidos;
            this.registrado = true;
            //this.resetForm();
            this.obtenerUsuarios();
            //this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            // this.obtenerProgramacionesCuatrimestre(this.datosProyeccionCuatrimestre.programacionAnualCNSId);
            //this.resetItemsProgCuatrimestre();

            this.btnRegistroLoading = false;
            //this.resetForm();
          } else {
            this.registrado = false;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarRolDisabled = false;
        })
        .catch(() => {
          this.btnEliminarRolDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    mostrarDialogEstado(usuario, cantidadRoles) {
      this.tipoAccion = cantidadRoles > 0 ? `Inhabilitar` : `Habilitar`;
      this.datosEstado.usuario = usuario;
      //this.datosAsignacion.proyectoId=proyectoId;
      this.datosEstado.nuevaRestrictiva = cantidadRoles > 0 ? "S" : "N";

      this.dialogSuspension = true;
    },

    async actualizarEstadoItem() {
      //?Si accion es 2 se realiza la actualizacion
      this.btnEstadoLoading = true;
      this.$store
        .dispatch(ACTUALIZAR_ESTADO_USUARIO_ADSIS, this.datosEstado)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogRegistro = false;
            this.obtenerUsuarios();
            //this.resetForm();
            this.dialogSuspension = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEstadoLoading = false;
        })
        .catch((error) => {
          this.btnEstadoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },
    mostrarDialogDesactivararRol(item){
      this.rolDesactivar = item;
      this.dialogDesactivarRol = true;
    },
    async eliminarRol(rol) {
      console.log(rol);
    },

    ///Registro de usuario
    registrarItem() {
      return "Listo";
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard" },
      { title: "Administración" },
      { title: "Usuarios Externos" },
    ]);
  },

  created() {
    this.obtenerUsuarios();
    this.obtenerRoles();
    this.obtenerEntidades();
    this.idUsuario = this.$route.params.idUsuario;
  },

  computed: {
    headers() {
      return [
        {
          text: "Usuario",
          align: "left",
          sortable: true,
          value: "usuario",
        },
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "nombre"
        },
        { text: "Correo", value: "email" },
        { text: "Institución", value: "institucion" },
        { text: "Rol", value: "rolesAsignados" },
        { text: "Estado ADSIS", value: "estado" },
        { text: "Acceso SICOOPERA", value: "estado" },
        { text: "Acciones", value: "iron" },
      ];
    },

    headersRoles() {
      return [
        { text: "Rol", value: "rol" },
        { text: "Acciones", value: "" },
      ];
    },

    likesAllRoles() {
      return this.rolSeleccionado.length === this.roles.length;
    },
    likesSomeRol() {
      return this.rolSeleccionado.length > 0 && !this.likesAllRoles;
    },

    icon() {
      if (this.likesAllRoles) return "mdi-close-box";
      if (this.likesSomeRol) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
};
</script>
